










































































































































































































































import Vue from "vue";
import { mapState } from "vuex";
import { download } from "@/utils";
import "swiper/swiper-bundle.min.css";

export default Vue.extend({
  data() {
    return {
      colorOptions: {
        speed: 600,
        shortSwipes: false,
        longSwipesMs: 50,
        longSwipesRatio: 0.05,
        navigation: {
          prevEl: ".color-prev",
          nextEl: ".color-next"
        }
      },
      communityOptions: {
        speed: 600,
        shortSwipes: false,
        longSwipesMs: 50,
        longSwipesRatio: 0.05
      }
    };
  },
  computed: {
    ...mapState("app", {
      language: "language",
      isCN: "isCN",
      isMobile: "isMobile"
    }),
    ...mapState("assets", {
      url: "url",
      oss: "oss"
    })
  },
  methods: {
    onDownload() {
      download(this.oss.pdf_am_afa);
    },
    onBuild() {
      download(this.oss.pdf_am_afa_build);
    },
    onVerify() {
      window.open(this.$paths.verify + "am-afa/", "_blank");
    }
  }
});
